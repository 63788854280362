import React from 'react';
import style from './style.css';

import { Redirect } from 'react-router-dom';
import { RegisterLoginForm } from 'cccisd-laravel-nexus';
import { Field, CccisdInput } from 'cccisd-formik';
import axios from 'cccisd-axios';
import notification from 'cccisd-notification';

const Boilerplate = window.cccisd && window.cccisd.boilerplate;

export default class Registration extends React.Component {
    state = {
        isUserRegistered: false,
    };

    registerProps = {
        title: 'If you do not have an account:',
        bottomInstructions:
            'Your account request will be reviewed within three business days. If you are approved, you will receive a notification email.',
        buttonText: 'Request Access',
        renderAdditionalFields: formikProps => (
            <div>
                <Field name="agency" component={CccisdInput} label="Agency:" />
                <Field name="title" component={CccisdInput} label="Title:" />
            </div>
        ),
        emailLabel: 'Email:',
        // role and onRegisterSuccess can replace onRegisterSubmit prop once cccisd-laravel-nexus frontend package is updated
        // role: 'guestUser',
        // onRegisterSuccess: async values => {
        //     this.setState({ isUserRegistered: true });
        // },
        onRegisterSubmit: async values => {
            values.role = 'guestUser';
            values.username = values.email;

            const response = await axios.post(Boilerplate.route('check.user.registration'), values);
            if (response.data.status === 'success') {
                this.setState({ isUserRegistered: true });
            } else if (response.data.errors.values) {
                // This is a general error with the request, like "missing username"
                notification({
                    message: `${response.data.errors.values[0]}`,
                    type: 'danger',
                    duration: 5000,
                });
            } else {
                throw response.data.errors;
            }
        },
        validateAdditionalFields: values => {
            let errors = {};
            if (!values.first_name) {
                errors.first_name = 'You must provide a first name.';
            }
            if (!values.last_name) {
                errors.last_name = 'You must provide a last name.';
            }
            if (!values.email) {
                errors.email = 'You must provide a valid email address.';
            }
            if (!values.agency) {
                errors.agency = 'You must provide an agency name.';
            }
            if (!values.title) {
                errors.title = 'You must provide a title.';
            }
            return errors;
        },
    };

    loginProps = {
        title: 'If you do have an account:',
        showPasswordToggle: true,
    };

    render() {
        const { isUserRegistered } = this.state;

        return isUserRegistered ? (
            <Redirect push to="/underReview" />
        ) : (
            <div className={style.registrationPage}>
                <RegisterLoginForm
                    registerProps={this.registerProps}
                    loginProps={this.loginProps}
                />
            </div>
        );
    }
}
