import React from 'react';
import style from './style.css';
import Modal from 'cccisd-modal';

// Hardcoded Content
const allContent = {
    // Pre-Exposure Training
    course1: [
        {
            categoryText: 'Articles',
            links: [
                <span>
                    Hofmann, W., Finkel, E. J., & Fitzsimmons, G. M. (2015).{' '}
                    <a
                        href="https://www.scholars.northwestern.edu/en/publications/close-relationships-and-self-regulation-how-relationship-satisfac-2"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        Close relationships and self-regulation: How relationship satisfaction
                        facilitates monetary goal pursuit.
                    </a>{' '}
                    Journal of Personality and Social Psychology, 109(3), 434-452.
                </span>,
            ],
        },
        {
            categoryText: 'Websites',
            links: [
                <span>
                    <a
                        href="https://www.verywellmind.com/"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        Very Well Mind
                    </a>
                </span>,
            ],
        },
        {
            categoryText: 'Books',
            links: [
                <span>
                    Banitt, S. P. (2012).{' '}
                    <em>The trauma tool kit: Healing PTSD from the Inside Out.</em> Quest Books.
                </span>,

                <span>
                    Gentry, J. E. (2016).{' '}
                    <em>Forward-facing trauma therapy: Healing the moral wound.</em> Compassion
                    Unlimited.
                </span>,

                <span>
                    Graham, L. (2013).{' '}
                    <em>
                        Bouncing back: Rewiring your brain for maximum resilience and well-being.
                    </em>{' '}
                    New World Library.
                </span>,

                <span>
                    Van Der Kolk, B. (2014).{' '}
                    <em>
                        The body keeps the score: Brain, mind, and body in the healing of trauma.
                    </em>{' '}
                    Viking.
                </span>,
            ],
        },
        {
            categoryText: 'Contacts',
            links: [
                <span>
                    <a
                        href="https://www.shiftwellness.org/"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        www.SHIFTwellness.org
                    </a>
                </span>,
                <span>
                    Beth Medina, SHIFT Program Director:{' '}
                    <a href="mailto:beth@innocentjustice.org">beth@innocentjustice.org</a>
                </span>,
                <span>
                    Elizabeth Tow, SHIFT Program Manager:{' '}
                    <a href="mailto:elizabeth@innocentjustice.org">elizabeth@innocentjustice.org</a>
                </span>,
            ],
        },
    ],
    // Pre-Exposure Booster
    course2: [
        {
            categoryText: 'Articles',
            links: [
                <span>
                    Hofmann, W., Finkel, E. J., & Fitzsimmons, G. M. (2015).{' '}
                    <a
                        href="https://www.scholars.northwestern.edu/en/publications/close-relationships-and-self-regulation-how-relationship-satisfac-2"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        Close relationships and self-regulation: How relationship satisfaction
                        facilitates monetary goal pursuit.
                    </a>{' '}
                    Journal of Personality and Social Psychology, 109(3), 434-452.
                </span>,
            ],
        },
        {
            categoryText: 'Websites',
            links: [
                <span>
                    <a
                        href="https://www.verywellmind.com/"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        Very Well Mind
                    </a>
                </span>,
            ],
        },
        {
            categoryText: 'Books',
            links: [
                <span>
                    Banitt, S. P. (2012).{' '}
                    <em>The trauma tool kit: Healing PTSD from the Inside Out.</em> Quest Books.
                </span>,

                <span>
                    Gentry, J. E. (2016).{' '}
                    <em>Forward-facing trauma therapy: Healing the moral wound.</em> Compassion
                    Unlimited.
                </span>,

                <span>
                    Graham, L. (2013).{' '}
                    <em>
                        Bouncing back: Rewiring your brain for maximum resilience and well-being.
                    </em>{' '}
                    New World Library.
                </span>,

                <span>
                    Van Der Kolk, B. (2014).{' '}
                    <em>
                        The body keeps the score: Brain, mind, and body in the healing of trauma.
                    </em>{' '}
                    Viking.
                </span>,
            ],
        },
        {
            categoryText: 'Contacts',
            links: [
                <span>
                    <a
                        href="https://www.shiftwellness.org/"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        www.SHIFTwellness.org
                    </a>
                </span>,
                <span>
                    Beth Medina, SHIFT Program Director:{' '}
                    <a href="mailto:beth@innocentjustice.org">beth@innocentjustice.org</a>
                </span>,
                <span>
                    Elizabeth Tow, SHIFT Program Manager:{' '}
                    <a href="mailto:elizabeth@innocentjustice.org">elizabeth@innocentjustice.org</a>
                </span>,
            ],
        },
    ],
    // Leadership Attunement
    course3: [
        {
            categoryText: 'Articles',
            links: [
                <span>
                    <a
                        href="https://www.entrepreneurship.org/videos/leadership-and-motivation/attunement-in-leadership"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        Leadership and Motivation: Attunement in Leadership
                    </a>
                </span>,

                <span>
                    <a
                        href="https://www.inc.com/michael-schneider/outstanding-leaders-exhibit-more-than-just-emotion.html"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        Outstanding Leaders Exhibit More Than Just Emotional Intelligence
                    </a>
                </span>,

                <span>
                    <a
                        href="https://sarahrozenthuler.com/purpose-led-leadership-three-adventures/"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        Purpose-led Leadership: The Three Adventures
                    </a>
                </span>,

                <span>
                    <a
                        href="https://executiveeducation.wharton.upenn.edu/thought-leadership/wharton-at-work/2013/03/sell-ideas-through-attunement/"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        Sell Your Ideas Through Attunement
                    </a>
                </span>,

                <span>
                    <a
                        href="https://www.forbes.com/sites/rhettpower/2019/07/09/the-best-books-on-leadership-for-2019/#4efaa88d5f32"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        The Best Books on Leadership for 2019
                    </a>
                </span>,
            ],
        },
        {
            categoryText: 'Websites',
            links: [
                <span>
                    <a
                        href="https://www.inc.com/shawn-murphy/10-great-apps-every-leader-needs-to-be-effective.html"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        Ten Great Apps Every Leader Needs to Be Effective
                    </a>
                </span>,
                <span>
                    <a
                        href="https://igniter.innerwill.org/"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        Igniter from Innerwill
                    </a>
                </span>,
                <span>
                    <a
                        href="https://www.leadershipchallenge.com/"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        The Leadership Challenge
                    </a>
                </span>,
            ],
        },
        {
            categoryText: 'Books',
            links: [
                <span>
                    Brown, Brené (2018).{' '}
                    <em>Dare to Lead: Brave Work. Tough Conversations. Whole Hearts.</em> Random
                    House.
                </span>,
                <span>
                    Sinek, Simon (2014).{' '}
                    <em>Leaders Eat Last: Why Some Teams Pull Together and Others Don’t.</em>{' '}
                    Portfolio.
                </span>,
            ],
        },
        {
            categoryText: 'Contacts',
            links: [
                <span>
                    <a
                        href="https://www.shiftwellness.org/"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        www.SHIFTwellness.org
                    </a>
                </span>,
                <span>
                    Beth Medina, SHIFT Program Director:{' '}
                    <a href="mailto:beth@innocentjustice.org">beth@innocentjustice.org</a>
                </span>,
                <span>
                    Elizabeth Tow, SHIFT Program Manager:{' '}
                    <a href="mailto:elizabeth@innocentjustice.org">elizabeth@innocentjustice.org</a>
                </span>,
            ],
        },
    ],
    course4: [],
    // Support Systems Structured for Wellness, Part 1
    course5: [
        {
            links: [
                <span>
                    <a
                        href="https://dm0gz550769cd.cloudfront.net/shiftwellness/d8/d8f6a469ef04e14ccf1e736ab77f12d9.pdf"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        Technology Tools for Wellness
                    </a>
                </span>,

                <span>
                    <a
                        href="https://dm0gz550769cd.cloudfront.net/shiftwellness/9e/9eac1680ceb1f81a842d8467d3ded4f0.pdf"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        Roadmap to Wellness
                    </a>
                </span>,

                <span>
                    <a
                        href="https://dm0gz550769cd.cloudfront.net/shiftwellness/e1/e1e20d98cc2a17192b9282f901081199.pdf"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        Support System Action Plan
                    </a>
                </span>,
            ],
        },
    ],
    // Support Systems Structured for Wellness, Part 2
    course6: [
        {
            links: [
                <span>
                    <a
                        href="https://dm0gz550769cd.cloudfront.net/shiftwellness/d8/d8f6a469ef04e14ccf1e736ab77f12d9.pdf"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        Technology Tools for Wellness
                    </a>
                </span>,

                <span>
                    <a
                        href="https://dm0gz550769cd.cloudfront.net/shiftwellness/9e/9eac1680ceb1f81a842d8467d3ded4f0.pdf"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        Roadmap to Wellness
                    </a>
                </span>,

                <span>
                    <a
                        href="https://dm0gz550769cd.cloudfront.net/shiftwellness/e1/e1e20d98cc2a17192b9282f901081199.pdf"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        Support System Action Plan
                    </a>
                </span>,
            ],
        },
    ],
};

const AdditionalResourcesModal = props => {
    if (!props.courseHandle || !allContent[props.courseHandle]) {
        return null;
    }

    let linkCategories = allContent[props.courseHandle];

    return (
        <Modal
            size="medium"
            trigger={
                <button type="button" className={`btn btn-default ${props.btnClass}`}>
                    Resources
                </button>
            }
            title={'Additional resources for ' + props.courseTitle || 'Additional Resources'}
        >
            {linkCategories.length ? (
                <ul className={style.resourceList}>
                    {linkCategories.map((category, i) => (
                        <li key={i}>
                            <ul>
                                {category.categoryText && <h5>{category.categoryText}</h5>}
                                {category.links.map((link, j) => (
                                    <li key={j}>{link}</li>
                                ))}
                            </ul>
                        </li>
                    ))}
                </ul>
            ) : (
                <p>No additional resources for this training.</p>
            )}
        </Modal>
    );
};

export default AdditionalResourcesModal;
