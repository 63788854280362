import React from 'react';
import style from './style.css';

import TopTrainingLink from './TopTrainingLink';
import TrainingLink from './TrainingLink';
import { client as apollo } from 'cccisd-apollo';

import coursesDeploymentQuery from './coursesDeploymentQuery.graphql';

const Fortress = window.cccisd.fortress;

// These are just for the placeholder tiles until the actual courses are created
const placeholderLabels = {
    course4: `Finding a Mental Health Professional`,
    course5: `Support Systems Structured for Wellness, Part 1`,
    course6: `Support Systems Structured for Wellness, Part 2`,
};
const placeholderDescriptions = {
    course4: `Highlights the value of implementing wellness practices at 
    the agency level, guides assessment of agency needs, and explores best 
    practices for finding the right mental health professional for your team.`,
    course5: `Explains the importance of support systems training, 
    gives an overview of traumatic stress, and provides tools, techniques, 
    and resources to support and strengthen resiliency and well-being.`,
    course6: `Builds on the information provided in part 1. Provides 
    practical suggestions for using and strengthening one’s personal support system.`,
};

/** **************************************
 * Note: Must use the specific deploymentHandle's listed
 * as variables below in order to fetch the courses.
 **************************************** */

class TrainingPage extends React.Component {
    state = {
        course1: null,
        course2: null,
        course3: null,
        course4: null,
        course5: null,
        course6: null,
        isFetchingCourses: true,
        isPlayingCourse: false,
    };

    course1Handle = 'course1';
    course2Handle = 'course2';
    course3Handle = 'course3';
    course4Handle = 'course4';
    course5Handle = 'course5';
    course6Handle = 'course6';

    componentDidMount() {
        this.getCourses();

        /* This is a hacky way to fire getCourses() whenever the modal closes.
         * since there is no "onClose" prop for the DeploymentPlayer component.
         * onMouseEnter will only fire after the course modal closes and the user
         * hovers on training page, which will fetch for updated courses. */
        document.getElementById('app').onmouseenter = this.fetchCourseOnMouseEnter;
    }

    componentWillUnmount() {
        document
            .getElementById('app')
            .removeEventListener('mouseenter', this.fetchCourseOnMouseEnter);
    }

    fetchCourseOnMouseEnter = () => {
        if (this.state.isPlayingCourse) {
            this.setState({ isPlayingCourse: false }, () => this.getCourses());
        }
    };

    setIsPlayingCourse = bool => {
        // Timeout needed to give the modal 1 second to slide down
        setTimeout(() => this.setState({ isPlayingCourse: bool }), 1000);
    };

    getCourses = async () => {
        const response = await apollo.query({
            query: coursesDeploymentQuery,
            fetchPolicy: 'network-only',
            variables: {
                course1: this.course1Handle,
                course2: this.course2Handle,
                course3: this.course3Handle,
                course4: this.course4Handle,
                course5: this.course5Handle,
                course6: this.course6Handle,
                pawnId: Fortress.user.acting.id,
            },
        });

        let { course1, course2, course3, course4, course5, course6 } = response.data.flows;

        // Set default for courses 4, 5, and 6 if not created yet
        [course4, course5, course6].forEach((course, i) => {
            const courseName = `course${i + 4}`;
            course.label = course.label || placeholderLabels[courseName];
            course.description = course.description || placeholderDescriptions[courseName];
        });

        let courses = { course1, course2, course3, course4, course5, course6 };

        Object.keys(courses).forEach(courseName => {
            // For courses that are not created yet or have been deleted, just return
            if (!courses[courseName] || !courses[courseName].deploymentId) {
                return;
            }

            courses[courseName].isInProgress =
                response.data.roles.approvedUser[courses[courseName].deploymentHandle].started &&
                !response.data.roles.approvedUser[courses[courseName].deploymentHandle].completed;
            courses[courseName].isCompleted =
                response.data.roles.approvedUser[courses[courseName].deploymentHandle].completed;
        });

        this.setState({
            ...courses,
            isFetchingCourses: false,
        });
    };

    render() {
        let { course1, course2, course3, course4, course5, course6 } = this.state;
        let additionalCourses = [course2, course3, course4, course5, course6];
        let trainingItems = additionalCourses.map(
            course =>
                course && (
                    <div className={style.trainingItem} key={course.deploymentHandle}>
                        {!this.state.isFetchingCourses && (
                            <TrainingLink
                                course={course}
                                setIsPlayingCourse={this.setIsPlayingCourse}
                            />
                        )}
                    </div>
                )
        );

        const numOfAdditionalCourses = additionalCourses.filter(course => course).length;
        const isTwoColumns = (numOfAdditionalCourses + 1) % 3 === 0;

        return (
            <div>
                {course1 && (
                    <div className={`row ${style.topTrainingRow}`}>
                        <div className="col-xs-12">
                            <TopTrainingLink
                                course={course1}
                                setIsPlayingCourse={this.setIsPlayingCourse}
                            />
                        </div>
                    </div>
                )}
                <h3 className={style.otherTrainingHeader}>Other Trainings</h3>
                <div
                    className={`${style.otherTrainingContainer} ${isTwoColumns &&
                        style.hasTwoColumns}`}
                >
                    {trainingItems}
                </div>
            </div>
        );
    }
}

export default TrainingPage;
