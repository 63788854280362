import React from 'react';
import PropTypes from 'prop-types';
import style from './style.css';
import classnames from 'classnames';

import IconGame from 'cccisd-icons/gamepad3';
import IconVideo from 'cccisd-icons/play3';

export default class GameThumbnail extends React.Component {
    static propTypes = {
        title: PropTypes.string.isRequired,
        description: PropTypes.string,
        thumbSrc: PropTypes.string,
        type: PropTypes.string, // game, video, empty, none (default)
        link: PropTypes.string.isRequired,
    };

    static defaultProps = {
        description: '',
        thumbSrc: null,
        type: 'none',
    };

    render() {
        const { title, description, thumbSrc, type, link } = this.props;

        let image =
            thumbSrc !== null ? (
                <img
                    alt={title}
                    src={thumbSrc}
                    className={classnames(style.imageInterior, style.thumbImg)}
                />
            ) : (
                <div className={style.imageInterior} />
            );

        var bannerIcon = null;
        switch (type) {
            case 'game':
                bannerIcon = <IconGame />;
                break;
            case 'video':
                bannerIcon = <IconVideo />;
                break;
            case 'empty':
                bannerIcon = '';
                break;
            case 'none':
            default:
                bannerIcon = null;
                break;
        }

        return (
            <div
                className={style.thumb_wrapper}
                onClick={() => {
                    window.open(link, '_top');
                }}
            >
                <div className={style.imgArea}>
                    {image}
                    {bannerIcon !== null && (
                        <>
                            <div className={style.bannerShadow} />
                            <div className={style.banner} />
                            <div className={style.bannerIcon}>{bannerIcon}</div>
                        </>
                    )}
                </div>

                <div className={style.content}>
                    <h1>{title}</h1>
                    <p>{description}</p>
                    <a className="btn btn-xs btn-primary">Play</a>
                </div>
            </div>
        );
    }
}
