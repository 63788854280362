import React from 'react';
import PropTypes from 'prop-types';
import PrimaryNavBar from 'js/components/PrimaryNavBar';
import SecondaryNavBar from 'js/components/SecondaryNavBar';
import Footer from 'cccisd-footer';
import style from './style.css';

const Fortress = window.cccisd.fortress;

export default class MainFixedLayout extends React.Component {
    static propTypes = {
        children: PropTypes.node,
        className: PropTypes.string,
        showPrimaryNav: PropTypes.bool,
        showSecondaryNav: PropTypes.bool,
        showFooter: PropTypes.bool,
    };

    static defaultProps = {
        className: 'container',
        showPrimaryNav: true,
        showSecondaryNav: true,
        showFooter: true,
    };

    render() {
        return (
            <div className={style.wrapper}>
                {this.props.showPrimaryNav && <PrimaryNavBar className={this.props.className} />}

                {this.props.showSecondaryNav && Fortress.auth() && <SecondaryNavBar />}
                <div className={style.body}>
                    <div className={this.props.className}>{this.props.children}</div>
                </div>

                {this.props.showFooter && (
                    <div className={style.footer}>
                        <Footer className={this.props.className} />
                    </div>
                )}
            </div>
        );
    }
}
