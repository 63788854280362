import React from 'react';
import PropTypes from 'prop-types';
import { NavBar } from 'cccisd-laravel-boilerplate';
import classnames from 'classnames';
import style from './style.css';

import IconReturnArrow from 'cccisd-icons/enter4';

export default class Component extends React.Component {
    static propTypes = {
        className: PropTypes.string,
    };

    static defaultProps = {
        className: 'container',
    };

    render() {
        let showTheseProfileDataFields = {
            guestUser: ['title'],
            approvedUser: ['title'],
        };

        let logo = (
            <span className={style.logo}>
                <strong>SHIFT</strong> Wellness Resources
            </span>
        );

        return (
            <div>
                <NavBar {...this.props} logo={logo} showTheseProfileDataFields={showTheseProfileDataFields}>
                    <li className={style.buttonArea}>
                        <span className={style.linkContainer}>
                            <a
                                href="https://www.shiftwellness.org/"
                                className={classnames(style.returnButton, 'btn btn-xs btn-default')}
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                <IconReturnArrow className={style.returnArrow} />
                                Return to SHIFT Wellness
                            </a>
                        </span>
                    </li>
                </NavBar>
            </div>
        );
    }
}
