import React from 'react';
import style from './style.css';
import classNames from 'classnames';

const UnderReview = () => {
    return (
        <div>
            <h3 className={classNames(style.header, style.bsOverride)}>
                Your account is currently under review.
            </h3>
            <p>
                Your request will be reviewed in the next three business days. You will receive an
                email from us informing you if your request was granted.
            </p>
            <div className={style.buttonContainer}>
                <a href="https://www.shiftwellness.org">
                    <button type="button" className={`btn btn-primary ${style.backBtn}`}>
                        Back to <strong>SHIFT</strong> Wellness
                    </button>
                </a>
            </div>
        </div>
    );
};

export default UnderReview;
