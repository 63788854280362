import React from 'react';
import PropTypes from 'prop-types';
import Footer from 'cccisd-footer';
import PrimaryNavBar from 'js/components/PrimaryNavBar';
import SecondaryNavBar from '../../components/SecondaryNavBar';
import style from './style.css';
import classnames from 'classnames';
import Banner from '../../components/Banner';

const Fortress = window.cccisd && window.cccisd.fortress;

export default class GameFixedLayout extends React.Component {
    static propTypes = {
        children: PropTypes.node,
        className: PropTypes.string,
        showPrimaryNav: PropTypes.bool,
        showSecondaryNav: PropTypes.bool,
        showFooter: PropTypes.bool,
        bannerType: PropTypes.string,
    };

    static defaultProps = {
        className: 'container',
        showPrimaryNav: true,
        showSecondaryNav: true,
        showFooter: true,
    };

    render() {
        return (
            <div className={classnames(style.wrapper, 'container-fluid')}>
                {this.props.showPrimaryNav && <PrimaryNavBar className={this.props.className} />}

                {this.props.showSecondaryNav && Fortress.auth() && (
                    <div className={style.secondaryNav}>
                        <SecondaryNavBar className={this.props.className} />
                    </div>
                )}

                {this.props.bannerType && <Banner bannerType={this.props.bannerType} />}

                <div className={classnames(style.body, 'container-fluid')}>
                    <div className="container">{this.props.children}</div>
                </div>

                <div className={style.footer}>
                    <Footer className="container" />
                </div>
            </div>
        );
    }
}
