import React from 'react';
import { Switch } from 'react-router-dom';
import defaultProps from 'recompose/defaultProps.js';
import { Route, NotFound } from 'cccisd-laravel-boilerplate';
import AppDefs, { Dashboard as AppdefDashboard, Manage as AppdefManage, AdminRoutes } from 'cccisd-laravel-appdefs';
import { Quest } from 'cccisd-laravel-assignment';
import { ResourceCenter, ResourceManager } from 'cccisd-laravel-resources';

// Components
import AuthRedirect from 'bundle-loader?lazy!./components/AuthRedirect';

// Laravel packages
/* eslint-disable import/no-extraneous-dependencies, import/no-unresolved */
import Nexus from 'bundle-loader?lazy!cccisd-laravel-nexus';

// Layouts
import MainLayout from './layouts/MainLayout';
import GameLayout from './layouts/GameLayout';

// Pages
import GameCenter from './pages/GameCenter';
import Register from './pages/Register';
import Training from './pages/Training';
import UnderReview from './pages/Register/UnderReview';

// Bind MainLayoutFluid by default
const MainLayoutNoSecondaryNav = defaultProps({
    showSecondaryNav: false,
})(MainLayout);
const MainLayoutFluid = defaultProps({ className: 'container-fluid' })(MainLayout);
const AppRoute = defaultProps({ layout: MainLayout })(Route);

let Fortress = window.cccisd.fortress;
let AppDefinitions = window.cccisd.appDefs;

const match = {
    AppdefDashboard,
    AppdefManage,
    Register,
    UnderReview,
    GameCenter,
    ResourceCenter,
    Training,
    ResourceManager,
};

const layoutMatch = {
    MainLayout,
    MainLayoutFluid,
    MainLayoutNoSecondaryNav,
    GameLayout,
};

export default () => (
    <Switch>
        {AppDefinitions.routes.map(route => {
            const Component = match[route.componentHandle];
            if (!Component) {
                return null;
            }

            return (
                <AppRoute
                    key={route.handle}
                    path={route.url}
                    component={Component}
                    componentProps={route.componentProps}
                    exact
                    layout={layoutMatch[route.layout] || MainLayout}
                    layoutProps={route.layoutProps}
                />
            );
        })}

        <AppRoute path={['/quest/preview', '/d', '/survey']} component={Quest} layout={null} />
        <AppRoute path="/account" component={Nexus} layout={MainLayoutNoSecondaryNav} />

        {/* Laravel packages routes */}
        <AppRoute path={Fortress.settings.config.after_login_url} component={AuthRedirect} exact />

        {AdminRoutes(MainLayoutNoSecondaryNav)}
        {Fortress.hasAccess('appdefs.admin') && (
            <AppRoute path="/appdefs" component={AppDefs} layout={MainLayoutFluid} />
        )}

        {Fortress.hasAccess('resources.admin') && (
            <AppRoute path="/resources" component={ResourceManager} layout={MainLayoutFluid} />
        )}

        <AppRoute path="/register/:pathName?" component={Register} />
        <AppRoute path={Fortress.settings.config.after_login_url} component={AuthRedirect} exact />

        {!Fortress.auth() && <AppRoute path="/:pathName?" component={AuthRedirect} exact />}

        {/* Not found page */}
        <AppRoute component={NotFound} />
    </Switch>
);
