import React from 'react';
import style from './style.css';

import GameThumbnail from '../../components/GameThumbnail';

import Thumb1 from '../../../images/swresources/BreatheAway.png';
import Thumb2 from '../../../images/swresources/Bugzz.png';
import Thumb3 from '../../../images/swresources/Bricked.png';
import Thumb4 from '../../../images/swresources/FireAndIce.png';
import Thumb5 from '../../../images/swresources/InfiniteRunner.png';
import Thumb6 from '../../../images/swresources/RelaxBodyandMind.png';

const Boilerplate = window.cccisd && window.cccisd.boilerplate;

export default class GameCenter extends React.Component {
    render() {
        let relax = 'Relax your body and mind';
        let distract = 'Distract your mind';

        return (
            <div className={style.gameCenterPage}>
                <div className={style.gameGrid}>
                    <GameThumbnail
                        title="Breathe Away"
                        thumbSrc={Thumb1}
                        type="game"
                        description={relax}
                        link={Boilerplate.url('/survey/bagame')}
                    />
                    <GameThumbnail
                        title="Bugzz"
                        thumbSrc={Thumb2}
                        type="game"
                        description={distract}
                        link={Boilerplate.url('/survey/ssgame')}
                    />
                    <GameThumbnail
                        title="Brick'd"
                        thumbSrc={Thumb3}
                        type="game"
                        description={distract}
                        link={Boilerplate.url('/survey/brickedgame')}
                    />
                    <GameThumbnail
                        title="Fire and Ice"
                        thumbSrc={Thumb4}
                        type="game"
                        description={distract}
                        link={Boilerplate.url('/survey/pbgame')}
                    />
                    <GameThumbnail
                        title="Cross Country Dash"
                        thumbSrc={Thumb5}
                        type="game"
                        description={distract}
                        link={Boilerplate.url('/survey/irgame')}
                    />
                    <GameThumbnail
                        title="Relaxation Exercises"
                        thumbSrc={Thumb6}
                        type="video"
                        description={relax}
                        link={Boilerplate.url('/survey/pmrvideo')}
                    />
                </div>
            </div>
        );
    }
}
