import React from 'react';
import style from './style.css';

import { DeploymentPlayer } from 'cccisd-laravel-assignment';
import Modal from 'cccisd-modal';
import IconGo from 'cccisd-icons/arrow-right20';
import Photo from '../../../../images/swresources/IJF_LongTraining.png';
import AdditionalResourcesModal from '../AdditionalResourcesModal';

const Fortress = window.cccisd.fortress;

const TopTrainingLink = props => {
    let buttonText = 'Play';
    if (props.course.isCompleted) {
        buttonText = 'Replay';
    } else if (props.course.isInProgress) {
        buttonText = 'Resume';
    }

    return (
        <div className={style.container}>
            <div className={style.imageContainer} style={{ backgroundImage: `url(${Photo})` }} />
            <div className={style.linkContainer}>
                <h4 className={style.header}>
                    {props.course.label || <em className="font-italic">Untitled</em>}
                </h4>
                <div className={style.description}>
                    <p>{props.course.description || 'Click below to take this course.'}</p>
                    <Modal
                        size="large"
                        trigger={
                            <button
                                type="button"
                                className={'btn btn-primary ' + style.triggerBtn}
                                onClick={() => props.setIsPlayingCourse(true)}
                            >
                                {buttonText} <IconGo />
                            </button>
                        }
                        title={props.course.label && props.course.label}
                    >
                        <div>
                            <DeploymentPlayer
                                deploymentId={props.course.deploymentId}
                                pawnId={Fortress.user.acting.id}
                                pawnHash={Fortress.user.acting.random_hash}
                                onComplete={props.onComplete}
                                flowProps={{ hidePlayButton: true }}
                                disableLayout // <-- Styling bug if layout is enabled
                            />
                        </div>
                    </Modal>
                    <AdditionalResourcesModal
                        courseHandle={props.course.deploymentHandle}
                        btnClass={style.resourcesBtn}
                        courseTitle={props.course.label}
                    />
                </div>
            </div>
        </div>
    );
};

export default TopTrainingLink;
