import React from 'react';
import style from './style.css';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const gamesBannerContent = {
    bannerHeading: (
        <span>
            Mind and Mood <span style={{ fontWeight: 'bold' }}>SHIFT</span>ers
        </span>
    ),
    bannerMessage: (
        <span>
            The videos and games below are designed to provide relaxation or distraction. Both are
            effective at disrupting disturbing thought patterns you may be experiencing.
        </span>
    ),
    isBgColoredLgScreen: true,
    isBgColoredSmScreen: false,
};

const registerBannerContent = {
    bannerHeading: (
        <span>
            Welcome to <span style={{ fontWeight: 'bold' }}>SHIFT</span> Wellness Resources
        </span>
    ),
    isBgColoredLgScreen: true,
    isBgColoredSmScreen: true,
};

const trainingBannerContent = {
    bannerHeading: <span>Training Center</span>,
    bannerMessage: (
        <span>
            Select a training or presentation below to access content that provides education and
            support on a variety of topics relevant to ICAC Commanders and officers, as well as
            their friends and family members.
        </span>
    ),
    isBgColoredLgScreen: true,
    isBgColoredSmScreen: false,
};

const resourceCenterBannerContent = {
    bannerHeading: <span>Resource Center</span>,
    bannerMessage: (
        <span>
            Welcome to the SHIFT Wellness Resource Center. Here you can access best practice videos,
            helpful guides, and a selection of wellness policies from ICAC programs across the
            country. To find resources specific to your role or to view resources designed to be
            used in conjunction with a MassCat event, use the filter checklist below.
        </span>
    ),
    isBgColoredLgScreen: true,
    isBgColoredSmScreen: false,
};

const contentMapping = {
    register: registerBannerContent,
    games: gamesBannerContent,
    training: trainingBannerContent,
    resourceCenter: resourceCenterBannerContent,
};

const Banner = props => {
    let content = contentMapping[props.bannerType];

    return (
        <div
            className={classNames(
                style.banner,
                { [style.isBgColoredLgScreen]: content.isBgColoredLgScreen },
                { [style.isBgColoredSmScreen]: content.isBgColoredSmScreen },
                'container-fluid'
            )}
        >
            <div className="container">
                {content.bannerHeading && <h2>{content.bannerHeading}</h2>}
                {content.bannerMessage && <p>{content.bannerMessage}</p>}
            </div>
        </div>
    );
};

Banner.propTypes = {
    bannerType: PropTypes.string.isRequired,
};

export default Banner;
