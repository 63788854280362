import React from 'react';
import style from './style.css';
import classNames from 'classnames';
import { Nav } from 'cccisd-laravel-appdefs';

const AppDefs = window.cccisd.appDefs;

const SecondaryNav = props => {
    return (
        <Nav
            className={classNames(props.className, 'container', style.secondaryNav)}
            navs={AppDefs.navs}
            routes={AppDefs.routes}
            isInverse
        />
    );
};

export default SecondaryNav;
